
import CompTable from "@/components/comp-table.vue"

export default {
    components: {
        CompTable,
    },

    activated() {
        this.$refs.comp_table.refresh()
    },

    data() {
        return {
            columns: [
                {
                    title: "车辆",
                    key: "vehicle",
                },
                {
                    title: "类型",
                    width: 150,
                    render: (h: Function, params: obj) => {
                        return { 1: "维护保养", 2: "年审" }[params.row.type] || "其他"
                    },
                },
                {
                    title: "维护项目",
                    key: "project",
                },
                {
                    title: "费用",
                    render: (h: Function, params: obj) => {
                        return "￥" + (!params.row.cost ? "0.00" : (params.row.cost / 100).toFixed(2))
                    },
                },
                {
                    title: "备注",
                    key: "remark",
                },
                {
                    title: "创建日期",
                    key: "createdDate",
                },
                {
                    title: "操作",
                    fixed: "right",
                    slot: "row-operate",
                    width: 200,
                },
            ],
        }
    },
}
