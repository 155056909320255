import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Option = _resolveComponent("Option")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_comp_table = _resolveComponent("comp-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_comp_table, {
      ref: "comp_table",
      title: "维护保养",
      entity: "Maintenance",
      columns: $data.columns
    }, {
      search: _withCtx((evt) => [
        _createVNode(_component_Select, {
          modelValue: evt.search.type,
          "onUpdate:modelValue": ($event: any) => ((evt.search.type) = $event),
          placeholder: "类型",
          style: {"width":"200px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([
                            { name: '维护保养', value: '1' },
                            { name: '年审', value: '2' },
                        ], (item) => {
              return _createVNode(_component_Option, {
                value: item.value,
                key: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])
      ]),
      "row-operate": _withCtx((params) => [
        _createVNode(_component_Button, {
          type: "info",
          style: {"margin":"3px"},
          onClick: ($event: any) => (_ctx.$router.push('/maintaining/form?id=' + params.row.uuid))
        }, {
          default: _withCtx(() => [
            _createTextVNode("编辑")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_Button, {
          type: "error",
          style: {"margin":"3px"},
          onClick: ($event: any) => (params.event.delete({ uuid: params.row.uuid }))
        }, {
          default: _withCtx(() => [
            _createTextVNode("删除")
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["columns"])
  ]))
}